<template>
    <!--档案-->
    <div>
        <div class="plateBox srhBox flex_l_c">
            <input type="text" class="mainBdColor srhIpt" placeholder="请输入题名/附件类型/年度/档号/文件编号" v-model="srhStr">
            <button class="srhBtn mainBdColor mainBgColor" @click="search"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选查询</span></div>
                <div class="seeMore pointer mainColor" @click="upAll">{{ upAllFlag ? '全部收起' : '全部展开' }}
                    <span :class="[upAllFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <div class="flex_b_t queryItem" v-show="upAllFlag">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">展览：</div>
                    <div :class="['flex1', unfoldExhibitionFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', exhibitionVal.id == item.id ? 'mainBgColor whiteColor' : 'resetColor']"
                             v-for="(item, index) in exhibitionTag" :key="index" @click="selExhibition(item, index)">
                            {{ item.exhibitionName }}
                        </div>
                        <div class="childExhibitionBox">
                            <div :class="[unfoldChildExhibitionFlag ? 'exhibitionTagBoxUnfold' : 'childExhibitionTagBox']">
                                <div
                                        :class="['exhibitionTagItem', childExhibitionVal.id == item.id ? 'mainBgColor whiteColor' : 'resetColor']"
                                        v-for="(item, index) in childExhibitionTag" :key="item.id"
                                        @click="selChildExhibition(item, index)">
                                    {{ item.directoryName }}
                                </div>
                            </div>
                            <div class="text_right">
                                <div class="mainColor unfoldBtn pointer" @click="unfoldChildExhibition">
                                    <span>{{ unfoldChildExhibitionFlag ? '收起' : '展开' }}</span>
                                    <span :class="[unfoldChildExhibitionFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldExhibition">
                    <span>{{ unfoldExhibitionFlag ? '收起' : '展开' }}</span>
                    <span :class="[unfoldExhibitionFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <div class="flex_b_t queryItem" v-show="upAllFlag">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">标签：</div>
                    <div :class="['flex1', unfoldLabelFlag ? 'exhibitionTagBoxUnfold' : 'exhibitionTagBox']">
                        <div :class="['exhibitionTagItem', labelVal.id == item.id ? 'mainBgColor whiteColor' : 'resetColor']"
                             v-for="(item, index) in labelTag" :key="index" @click="selLabel(item, index)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="mainBdColor mainColor unfoldBtn pointer" @click="unfoldLabel">
                    <span>{{ unfoldLabelFlag ? '收起' : '展开' }}</span>
                    <span :class="[unfoldLabelFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
                </div>
            </div>
            <div class="flex_b_t queryItem">
                <div class="flex_l_t flex1">
                    <div class="mainColor tagTit">已选条件：</div>
                    <div class="flex1">
                        <el-tag effect="plain" closable @close="closeConditions(0)" v-if="exhibitionVal.exhibitionName">
                            {{
                            exhibitionVal.exhibitionName }} <span v-if="childExhibitionVal.directoryName">/</span>{{
                            childExhibitionVal.directoryName }}
                        </el-tag>
                        <el-tag effect="plain" closable @close="closeConditions(1)" v-if="labelVal.name">{{
                            labelVal.name }}
                        </el-tag>
                        <el-tag effect="plain" closable @close="clearFiltrate(index)" v-for="(item, index) in advanced"
                                :key="index">{{ item.recordName }}{{ item.conditionName }}{{ item.condition }}
                        </el-tag>
                        <el-tag class="addFiltrate pointer" @click="advancedQuery">增加筛选</el-tag>
                    </div>
                </div>
                <div class="clearAllBtn pointer" @click="clearAll">清除全部</div>
            </div>
        </div>
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>筛选结果/操作</span></div>
                <div>
                    <el-button size="small" type="primary" plain @click="download()"
                               :disabled="dataListSelections.length == 0">下载
                    </el-button>
                    <el-button size="small" type="primary" plain @click="collect()"
                               :disabled="dataListSelections.length == 0">收藏
                    </el-button>
                    <el-button size="small" type="primary" plain @click="borrow()"
                               :disabled="dataListSelections.length == 0">借阅
                    </el-button>
                    <el-button-group class="m_l1">
                        <el-button size="small" :type="showList ? '' : 'primary'" @click="showList = false">卡片
                        </el-button>
                        <el-button size="small" :type="showList ? 'primary' : ''" @click="showList = true">列表
                        </el-button>
                    </el-button-group>
                </div>
            </div>
            <div class="noData" v-if="exhibitionList.length == 0 && !showList">暂无数据</div>
            <!--卡片-->
            <div v-if="!showList && exhibitionList.length > 0" class="exhibitionDetail m_t1">
                <div class="recordItem pointer" v-for="(item, index) in exhibitionList" @click="view(item)"
                     :key="index">
                    <el-image v-if="item.fileFormat == 'docx' || item.fileFormat == 'doc'" fit="contain"
                              :src="require('@/assets/img/word.png')" class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'rar' || item.fileFormat == 'zip'" fit="contain"
                              :src="require('@/assets/img/zip.png')" class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'pdf'" fit="contain" :src="require('@/assets/img/pdf.png')"
                              class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'ppt' || item.fileFormat == 'pptx'" fit="contain"
                              :src="require('@/assets/img/ppt.png')" class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'xls' || item.fileFormat == 'xlsx'" fit="contain"
                              :src="require('@/assets/img/excel.png')" class="itemImg"></el-image>
                    <el-image
                            v-else-if="item.fileFormat == 'psd' || item.fileFormat == 'tif' || item.fileFormat == 'JPG' || item.fileFormat == 'jpg' || item.fileFormat == 'png' || item.fileFormat == 'PNG' || item.fileFormat == 'jpeg' || item.fileFormat == 'gif'"
                            fit="contain" :src="require('@/assets/img/img.png')" class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'mp3'" fit="contain"
                              :src="require('@/assets/img/audio.png')"
                              class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'mp4' || item.fileFormat == 'mov'" fit="contain"
                              :src="require('@/assets/img/video.png')" class="itemImg"></el-image>
                    <el-image v-else-if="item.fileFormat == 'txt'" fit="contain" :src="require('@/assets/img/text.png')"
                              class="itemImg"></el-image>
                    <el-image v-else fit="contain" :src="require('@/assets/img/file.png')" class="itemImg"></el-image>
                    <el-tooltip effect="dark" :content="item.fileName" placement="top">
                        <div class="info omit text_center">{{ item.fileName }}</div>
                    </el-tooltip>
                </div>
            </div>
            <!--列表-->
            <el-table v-show="showList" :data="exhibitionList" v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      class="table" ref="tableBox">
                <el-table-column type="selection" width="50" fixed/>
                <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname"
                                 :label="item.recordName"
                                 show-overflow-tooltip v-if="item.onShow == 0">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="item.fieldEnname == 'fileFormat'">{{ scope.row[item.fieldEnname]
                            }}
                        </el-tag>
                        <div v-else v-show="scope.row[item.fieldEnname]">{{ scope.row[item.fieldEnname] }}</div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="view(scope.row)">查看</el-button>
                        <el-button type="text" @click="download(scope.row, 1)">下载</el-button>
                        <el-button type="text" @click="collect(scope.row.id, 1)" v-if="!scope.row.collectionId">收藏
                        </el-button>
                        <el-button type="text" v-else @click="cancelCollect(scope.row.collectionId)">取消收藏</el-button>
                        <el-button type="text" @click="borrow(scope.row, 1)">借阅</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
                           :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--高级检索-->
        <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
        <!--借阅-->
        <BorrowForm ref="borrowForm"></BorrowForm>
        <!--查看-->
        <DetailPage ref="detailPage" @refreshDataList="getDataList(advanced)"></DetailPage>
        <!--查看-->
        <!-- <BorrowForm ref="borrowForm"></BorrowForm> -->
    </div>
</template>

<script>
    import AdvancedQuery from '../search/advancedQuery' // 高级查询弹窗
    import BorrowForm from '../search/BorrowForm' // 借阅弹窗
    import DetailPage from '../search/detailPage' // 查看弹窗
    import Template from "../catalogue/template"; // 查看弹窗
    export default {
        components: {BorrowForm, AdvancedQuery, DetailPage, Template},
        props: ['homeSrh'],
        data() {
            return {
                srhStr: '',
                upAllFlag: true, // 全部展开
                // 展览
                unfoldExhibitionFlag: false,
                exhibitionIndex: 0,
                exhibitionVal: {
                    id: '',
                    exhibitionName: ''
                },
                exhibitionTag: [
                    {
                        id: '',
                        exhibitionName: '全部'
                    }
                ],
                childExhibitionIndex: 0,
                childExhibitionVal: {
                    id: '',
                    directoryName: ''
                },
                unfoldChildExhibitionFlag: false,
                childExhibitionTag: {
                    id: '',
                    directoryName: '全部'
                },
                // 标签
                unfoldLabelFlag: false,
                labelIndex: 0,
                labelVal: {name: '', id: ''},
                labelTag: [{name:'全部',id:''}],
                loading: false,
                showList: true,
                exhibitionList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                config: [],
                senior: false,
                dateList: [],
                // 增加筛选
                advanced: [],
                errorMsg: '',
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
            homeSrh(val) {
                this.srhStr = val;
                // this.tableData();
                this.getDataList(this.advanced);
            },
            srhStr(val) {
                this.$emit('changeSrh', val)
            },
        },
        created() {
            this.srhStr = this.homeSrh;
        },
        mounted() {
            this.init();
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
        },
        methods: {
            // 初始化
            init() {
                this.querySysOriginalDescriptionAll();
                this.labelIndexQueryAll();
                this.getCatalogue();
                if (this.$route.query.val) {
                    this.labelVal = this.$route.query.val
                }
                // this.tableData();
                this.getDataList(this.advanced);
            },
            // 搜索
            search() {
                this.pageNo = 1
                this.type = 0
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 增加筛选(高级查询)
            advancedQuery() {
                this.$refs.advancedQuery.init(this.config)
            },
            tableData() {
                // if(this.catalogueShuju || this.biaoSelect){
                this.loading = true
                this.$axios(this.api.zlzs.queryAllByLimitRetrieve, {
                    "name": this.srhStr,
                    "exhibitionId": this.exhibitionVal.id,
                    "directoryId": this.childExhibitionVal.id,
                    "labelId": this.labelVal.id,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 4,
                    'onDetails': 1,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.senior = false
                        this.$refs.tableBox.doLayout();
                    } else {
                        this.$message.error("查询失败")
                    }
                })
                // }
            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 4,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'quanzongNo') {
                                v.fieldEnname = 'exhibitionName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                        this.search();
                    }
                })
            },
            // 获取增加筛选(高级查询)数据
            searchGetDataList(data) {
                this.pageNo = 1
                this.type = 1
                this.dateList = data
                this.getDataList(data);
            },
            toUp(str) {
                let newStr = ''
                let arr = str.split('_') //先用字符串分割成数组
                arr.forEach((item, index) => {
                    if (index > 0) {
                        return newStr += item.replace(item[0], item[0].toUpperCase())
                    } else {
                        return newStr += item
                    }
                })
                return newStr
            },
            //高级查询检索返回
            getDataList(data) {
                this.advanced = JSON.parse(JSON.stringify(data));
                let dict = this.$dictUtils.getDictList('senior_retrieve')
                this.advanced.forEach(v => {
                    this.$set(v, 'eName', this.toUp(v.fieldName))
                })
                this.advanced.map(item => {
                    this.config.map(itemC => {
                        if (item.eName == itemC.fieldEnname) {
                            this.$set(item, 'recordName', itemC.recordName)
                        }
                    })
                    dict.map(itemD => {
                        if (item.equalOpt == itemD.value) {
                            this.$set(item, 'conditionName', itemD.label)
                        }
                    })
                })
                this.$axios(this.api.zlzs.seniorQuerySQL, {
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 4,
                    'onDetails': 1,
                    "seniorQueries": data,
                    "labelId": this.labelVal.id,
                    "directoryId": this.childExhibitionVal.id,
                    "exhibitionId": this.exhibitionVal.id,
                    "name": this.srhStr,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.exhibitionList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.senior = true
                        this.$refs.tableBox.doLayout();
                    }
                })
            },
            // 查询展览
            getCatalogue() {
                // this.exhibitionVal = {}
                this.$axios(this.api.zlzs.queryExhibitionRecord, {}, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionTag = data.data
                        this.exhibitionTag.unshift({exhibitionName: '全部', id: ''})
                        this.getDirectory();
                        // this.tableData();
                        this.getDataList(this.advanced);
                    }
                })
            },
            // 查询目录
            getDirectory() {
                this.childExhibitionTag = []
                // this.childExhibitionVal = {}
                if (this.exhibitionVal) {
                    this.$axios(this.api.zlzs.selectSysDirectory3, {
                        'exhibitionId': this.exhibitionVal.id
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.childExhibitionTag = data.data
                            this.childExhibitionTag.unshift({directoryName: '全部', id: ''})
                            // this.exhibitionShuju.id = this.catalogueList2[0].id
                            // this.exhibitionShuju.directoryName = this.catalogueList2[0].directoryName
                            this.total2 = parseInt(data.data.total)
                            // this.directoryId = this.catalogueList2[0].id
                        }
                    })
                }

                // }
            },
            // 选择展览
            selExhibition(item, index) {
                this.exhibitionIndex = index;
                this.exhibitionVal = item;
                this.pageNo = 1;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                this.getDirectory();
                // this.tableData()
                this.getDataList(this.advanced);

            },
            // 选择展览子级
            selChildExhibition(item, index) {
                this.childExhibitionIndex = index;
                this.childExhibitionVal = item;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            //文件标签
            labelIndexQueryAll() {
                this.$axios(this.api.zlzs.labelIndexList, {
                    "name": '',
                    "state": 1,
                    'pageNo': 1,
                    'pageSize': 9,
                }, 'get').then(data => {
                    if (data.status && data.data) {
                        this.labelTag = data.data.records
                        this.labelTag.unshift({name: '全部', id: ''})

                    }
                })
            },
            // 选择标签
            selLabel(item, index) {
                this.labelIndex = index;
                this.labelVal = item;
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 展览展开收起
            unfoldExhibition() {
                this.unfoldExhibitionFlag = !this.unfoldExhibitionFlag
            },
            // 展览子级展开收起
            unfoldChildExhibition() {
                this.unfoldChildExhibitionFlag = !this.unfoldChildExhibitionFlag
            },
            // 标签展开收起
            unfoldLabel() {
                this.unfoldLabelFlag = !this.unfoldLabelFlag
            },
            // 全部收起
            upAll() {
                this.upAllFlag = !this.upAllFlag
            },
            // 移除已选条件
            closeConditions(type) {
                if (type == 0) {
                    this.exhibitionVal = {
                        id: '',
                        exhibitionName: ''
                    };
                    this.childExhibitionVal = {
                        id: '',
                        directoryName: ''
                    };
                } else {
                    this.labelVal = {name: '', id: ''};
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // if(this.advanced.length>=0){
                //     this.getDataList(this.dateList)
                // }else{
                // this.tableData()
                this.getDataList(this.advanced);
                // }
            },
            // 清除新增筛选
            clearFiltrate(index) {
                this.advanced.splice(index, 1);
                let data = JSON.parse(JSON.stringify(this.advanced));
                data.map(v => {
                    this.$delete(v, 'eName')
                    this.$delete(v, 'recordName')
                    this.$delete(v, 'conditionName')
                })
                this.getDataList(data);
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 清除全部
            clearAll() {
                this.exhibitionIndex = 0;
                this.childExhibitionIndex = 0;
                this.labelIndex = 0;
                this.exhibitionVal = {
                    id: '',
                    exhibitionName: ''
                };
                this.childExhibitionVal = {
                    id: '',
                    directoryName: ''
                };
                this.labelVal = {name: '', id: ''};
                this.advanced = [];
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
                // this.tableData()
                this.getDataList(this.advanced);
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 收藏
            collect(id, val) {
                let ids = val == 1 ? id : this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$axios(this.api.zlzs.MyCollectionSave, {
                    fileIds: ids
                }, 'post').then(data => {
                    if (data.status) {
                        this.$message.success("收藏成功")
                    } else {
                        this.$message.error("收藏失败")
                    }
                    this.getDataList(this.advanced);
                })
            },
            // 取消收藏
            cancelCollect(collectionId) {
                this.$axios(this.api.zlzs.MyCollectionRemoveById, {
                    ids: collectionId
                }, 'delete').then(data => {
                    if (data.status) {
                        this.$message.success("取消收藏成功")
                    } else {
                        this.$message.error("取消收藏失败")
                    }
                    this.getDataList(this.advanced);
                })
            },
            // 借阅
            borrow(item, val) {
                let data = val == 1 ? [item] : this.dataListSelections
                this.$refs.borrowForm.init(data)
            },
            // 查看
            view(row) {
                this.$refs.detailPage.init(row.id, 'itemRetrieval')
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getDataList(this.advanced);
            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getDataList(this.advanced);

            },
            addWatermark(url) {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                return new Promise((resolve, reject) => {
                    this.$axios("zyd-catalogue/pdf/addWatermark", {
                        "target": url,
                        "sourceFile": sourceFile,
                        "watermark": watermark
                    }, 'post').then(data => {
                        if (data.status) {
                            resolve(data.data)
                        } else {
                            resolve("")
                        }
                    })
                })
            },
            // 下载
            download(row, val) {
                if (val != 1) {
                    this.dataListSelections.forEach(item => {
                        let api = this.api.zlzs.queryByIdRetrieve
                        this.$axios(api, {
                            'id': item.id,
                            'flag': 2,
                            'onDetails': 0
                        }, 'get').then(data => {
                            if (data.status) {
                                this.addWatermark(item.url).then(res => {
                                    let url = ""
                                    if (res) {
                                        url = res
                                    } else {
                                        url = item.url;
                                    }
                                    const a = document.createElement('a')
                                    fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                                        a.href = URL.createObjectURL(blob)
                                        a.download = item.fileName + "." + item.fileFormat // 下载文件的名字
                                        document.body.appendChild(a)
                                        a.click()
                                    })
                                })

                            } else {
                                this.$message.error("该文件【" + item.fileName + "】无下载权限，请提交借阅申请")
                            }

                        })
                    })

                } else {
                    let api = this.api.zlzs.queryByIdRetrieve
                    this.$axios(api, {
                        'id': row.id,
                        'flag': 2,
                        'onDetails': 0
                    }, 'get').then(data => {
                        if (data.status) {
                            this.addWatermark(row.url).then(res => {
                                let url = ""
                                if (res) {
                                    url = res
                                } else {
                                    url = row.url;
                                }
                                const a = document.createElement('a')
                                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                                    a.href = URL.createObjectURL(blob)
                                    a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
                                    document.body.appendChild(a)
                                    a.click()
                                })
                            })
                        } else {
                            this.$message.error("您暂无下载权限，请提交借阅申请")
                        }

                    })


                }


            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
        }
    }
</script>

<style scoped></style>
